import React from 'react';

import { Col, Row } from 'antd';

import { AppLinks, Contact, Logo, Social } from '../../components';

import logoWh from '../../assets/img/logo_wh.png';

const Footer = () => {
	return (
		<div>
			<div className='footer'>
				<Row>
					<Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
						<Row
							className='footer__root'
							type='flex'
							align='middle'
							justify='space-between'
						>
							<Logo src={logoWh} />
							<Contact isFooter={true} />
							<Social />
							<AppLinks />
						</Row>
					</Col>
				</Row>
			</div>
			<Row type='flex' justify='center' className='rights'>
				<Col lg={{ span: 24 }}>
					<p className='footer__rights'>
						Super Group &copy; Все права защищены, 2014-2024. Разработка -
						компания “Технологии и Бизнес”
					</p>
				</Col>
			</Row>
		</div>
	);
};

export default Footer;
