import React from 'react';

import { Col, Row } from 'antd';

import { Account, Contact, Logo, Slogan } from '../../components';

import logo from '../../assets/img/logo.png';

const Header = (props) => {
	return (
		<div className='header'>
			<Row>
				<Col md={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
					<Row
						className='header__root'
						type='flex'
						align='middle'
						justify='space-between'
					>
						<Logo src={logo} />
						<Contact />
						<Slogan />
						{/* <Location /> */}
						<Account onMenuClick={props.onMenuClick} />
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default Header;
